<template>
  <section>
    <div>
      <header>
        <el-row>
          <el-col :md="3" :lg="5">
            <el-button plain @click="goBack">Back</el-button>
          </el-col>
          <el-col :md="21" :lg="19">
            <el-steps :active="active" finish-status="success" :space="400">
              <el-step
                style="step-class"
                title="Upload file or Ask AI"
                @click.native="handleActiveChange(0)"
              ></el-step>
              <el-step
                style="step-class"
                title="Map Fields"
                @click.native="handleActiveChange(1)"
              ></el-step>
              <el-step
                style="step-class"
                title="Review & Import"
                @click.native="handleActiveChange(2)"
              ></el-step>
            </el-steps>
          </el-col>
        </el-row>
      </header>
      <hr />
      <section
        style="text-align: center; min-height: 400px; max-height: 550px"
        v-if="this.active === 0"
        v-loading="tableLoading"
      >
        <el-scrollbar
          v-if="cardLoading"
          wrap-style="max-height: 570px; overflow-x: auto; overflow-y: auto; scrollbar-width:thin;"
        >
          <loadingComponentVue
            loadingText="Generating your application.It will take upto 1-2 minutes.please wait...."
            :features="[]"
          ></loadingComponentVue>
        </el-scrollbar>
        <el-scrollbar
          wrap-style="max-height: 570px; overflow-x: auto; overflow-y: auto; scrollbar-width:thin;"
          v-else
        >
          <div>
            <el-radio-group v-model="creationType" size="mini">
              <el-radio-button label="Upload File"></el-radio-button>
              <el-radio-button label="Ask AI"></el-radio-button>
            </el-radio-group>
          </div>
          <div v-if="creationType == 'Upload File'" class="mt-2">
            <el-upload
              v-if="isExcelFileuploaded === false"
              class="upload-file-el-upload"
              drag
              name="logo"
              :on-change="uploadExcelfile"
              action
              :show-file-list="false"
              :auto-upload="false"
              accept=".xlsx"
            >
              <div class="upload-file">
                <div class="icon py-2">
                  <img
                    src="@/assets/img/icons/upload-file.svg"
                    alt="Upload File"
                    height="40"
                    width="100"
                  />
                </div>
                <p class="text-dark fs-9 mb-0">
                  Drag &amp; Drop Excel file here
                </p>
                <p class="fs-9 mb-0" style="color: #babdc2">OR</p>
                <button
                  class="match_upload_button mt-0"
                  style="padding: 6px 10px !important"
                >
                  Import Excel
                </button>
              </div>
            </el-upload>
            <div v-else-if="isExcelFileuploaded === true" class="mb-5">
              <img src="@/assets/img/mapped_file_uploaded_success.svg" />
              <br />
              <br />
              <h1>File Uploaded Successfully</h1>
            </div>
            <br />
            <div
              class="d-flex flex-row justify-content-center"
              v-if="isExcelFileuploaded === true"
            >
              <div class="uploaded-file-display pl-1 pr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="green"
                  class="bi bi-check2"
                  viewBox="0 0 16 16"
                  style="font-weight: 600px !important"
                >
                  <path
                    d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
                  />
                </svg>
                <img src="@/assets/img/entity_dots.svg" />
                <img src="@/assets/img/entity_excel.svg" />
                <p style="margin: auto auto auto 5px">
                  {{ excelFile.name }}
                </p>
                <div class="flex-grow-1"></div>
                <div style="cursor: pointer">
                  <a @click="removeExcelFile">
                    <i class="el-icon-circle-close"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="class-ai">
              <el-card class="box-card" shadow="never" v-if="!responseCaptured">
                <img src="@/assets/img/Describe.svg" />
                <p>Describe Your Application</p>
                <el-divider></el-divider>
                <p>Tell us what your application should do in a few words</p>
                <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="Please Enter Application Name"
                  v-model="application_prompt"
                  class="input-field"
                >
                </el-input>
                <div v-if="gotErrorResponse">
                  <span class="warning font-class"
                    >
                    {{errorResponseMessage || "Provided data is not sufficient to create your application"}}
                  </span>
                </div>
                <div class="generate-button">
                  <el-button
                    type="primary"
                    :disabled="application_prompt.length < 15"
                    @click="generateResponse()"
                    size="medium"
                    style="width: 100%"
                  >
                    Generate Application
                  </el-button>
                </div>
              </el-card>
              <div v-else>
                <div class="card-container mt-1">
                  <div
                    class="single-table"
                    v-for="table of tablesData"
                    :key="table.table_name"
                  >
                    <el-card class="box-card2 mb-1 card" shadow="never">
                      <h4>{{ table.table_name }}</h4>
                      <div class="mt-1">
                        <el-table
                          height="350"
                          :data="table.attributes"
                          border
                          size="mini"
                        >
                          <el-table-column prop="name" label="Name" width="160">
                          </el-table-column>
                          <el-table-column
                            prop="type"
                            label="Input type"
                            width="250"
                          >
                          </el-table-column>
                        </el-table>
                      </div>
                    </el-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </section>
      <section v-if="active === 1">
        <el-tabs v-model="activeNameForApplicationImport">
          <el-tab-pane
            v-for="tab in Object.keys(jsonDataOfExcel)"
            :label="tab"
            :key="tab"
            :name="tab"
          >
            <el-row>
              <div style="display: flex; gap: 20px">
                <div class="d-flex-col">
                  <span class="mr-1">Select Entity Type</span>
                  <span>
                    <el-select
                      v-if="showEntityTypes"
                      v-model="
                        excelFileHeadersApplication[
                          activeNameForApplicationImport
                        ]['entity_type']
                      "
                      @change="
                        addStandardTemp(
                          excelFileHeadersApplication[
                            activeNameForApplicationImport
                          ]['entity_type']
                        )
                      "
                      clearable
                      size="small"
                    >
                      <el-option
                        v-for="option in ['INDIVIDUAL', 'GENERAL', 'BUSINESS']"
                        :key="option"
                        :value="option"
                        :label="option"
                      >
                        {{ option }}
                        <i
                          class="el-icon-star-on"
                          v-if="option === 'BUSINESS'"
                        ></i>
                        <i
                          class="el-icon-user"
                          v-if="option === 'INDIVIDUAL'"
                        ></i>
                        <i
                          class="el-icon-magnet"
                          v-if="option === 'GENERAL'"
                        ></i>
                      </el-option>
                    </el-select>
                  </span>
                </div>
                <div class="d-flex-col">
                  <span>
                    Groups
                    <el-tooltip content="add new group">
                      <i
                        class="el-icon-circle-plus icon-class ml-05"
                        @click="addGroupPopUP"
                        style="color: #f754a2"
                      >
                      </i>
                    </el-tooltip>
                    <el-tooltip content="update groups in all entities">
                      <i
                        class="el-icon-refresh icon-class ml-05"
                        @click="updateGroupInAllEntities"
                        style="color: #f754a2"
                      >
                      </i> </el-tooltip
                  ></span>
                  <span>
                    <el-select
                      v-model="
                        excelFileHeadersApplication[
                          activeNameForApplicationImport
                        ]['groups']
                      "
                      multiple
                      v-if="showGroups"
                      @change="handleGroupChange"
                      :collapse-tags="true"
                      :loading="groupLoading"
                      size="small"
                    >
                      <el-option
                        v-for="option in groups"
                        :key="option._id"
                        :label="option.name"
                        :value="option._id"
                      >
                      </el-option>
                    </el-select>
                  </span>
                </div>
                <div class="d-flex-col">
                  <span> Choose Icon</span>
                  <span>
                    <div
                      style="cursor: pointer"
                      class="ml-3"
                      @click="iconPickerdialogVisible = true"
                    >
                      <icons
                        v-model="
                          excelFileHeadersApplication[
                            activeNameForApplicationImport
                          ]['entity_symbol']
                        "
                        :iconName="
                          excelFileHeadersApplication[
                            activeNameForApplicationImport
                          ]['entity_symbol']
                        "
                      ></icons></div
                  ></span>
                </div>
              </div>
            </el-row>
            <br />
            <el-row>
              <el-scrollbar wrap-style="max-height:55vh;" ref="configureScroll">
                <el-table
                  :data="
                    excelFileHeadersApplication[activeNameForApplicationImport][
                      'columnHeaders'
                    ]
                  "
                  border
                  style="width: 100%"
                  v-loading="tableLoading"
                >
                  <el-table-column label="Fields in File">
                    <template slot-scope="scope">
                      <p>{{ scope.row.excel_field }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column label="Form templates">
                    <template slot-scope="scope">
                      <div class="d-flex flex-row">
                        <el-select
                          clearable
                          filterable
                          v-model="scope.row.template_id"
                          placeholder="Select form template"
                          @change="validateSelectedRow(scope.row)"
                        >
                          <el-option
                            v-for="temp in excelFileHeadersApplication[
                              activeNameForApplicationImport
                            ]['formTemplates']"
                            :key="temp._id"
                            :label="temp.name"
                            :value="temp._id"
                          >
                            <div v-if="temp && temp.type == 'STANDARD'">
                              <span>{{ temp.name }}</span>
                              <span
                                style="color: #f754a2; font-weight: 800"
                                class="ml-2"
                                >STANDARD</span
                              >
                            </div>
                            <div v-else>
                              {{ temp.name }}
                            </div>
                          </el-option>
                        </el-select>
                        <i
                          class="el-icon-circle-plus"
                          style="color: #f754a2; margin-left: 10px"
                          @click="ShowTempDialog(scope.$index)"
                        ></i>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Form fields">
                    <template slot-scope="scope">
                      <div class="d-flex flex-row">
                        <el-select
                          v-if="
                            scope.row.template_id.includes('newTemplate') ===
                            true
                          "
                          v-model="scope.row.inputType"
                          placeholder="Select field"
                          @change="validateInputFields(scope.row)"
                          clearable
                        >
                          <el-option
                            v-for="field in allFormFIelds"
                            :key="field.key"
                            :label="field.name"
                            :value="field.key"
                          >
                            <div class="field-icon-container">
                              <img
                                :src="
                                  require('@/assets/img/fields/' +
                                    field.img_url)
                                "
                                style="width: 20px; padding-right: 10px"
                              />
                              {{ field.name }}
                            </div>
                          </el-option>
                        </el-select>
                        <el-select
                          v-else
                          v-model="scope.row.template_filed_id"
                          placeholder="Select field"
                          filterable
                          clearable
                        >
                          <el-option
                            v-for="(field, index) in getTemplateFields(
                              scope.row
                            )"
                            :key="field._id + index + field.label"
                            :label="field.label"
                            :value="field.key"
                            :disabled="checkIsFieldSelected(field)"
                          >
                            <div v-if="field && field.inputType == 'ENTITY'">
                              <span>{{ field.label }}</span>
                              <span
                                style="color: #f754a2; font-weight: 800"
                                class="ml-2"
                                >ENTITY</span
                              >
                            </div>
                            <div v-else>
                              {{ field.label }}
                            </div>
                          </el-option>
                        </el-select>
                        <el-select
                          v-if="
                            scope.row.inputType == 'ENTITY' &&
                            scope.row.template_id.includes('newTemplate') ===
                              true
                          "
                          v-model="scope.row.entity_id"
                          placeholder="Select entity"
                          filterable
                          class="ml-2"
                        >
                          <el-option
                            v-for="field in excelFileHeadersApplication[
                              activeNameForApplicationImport
                            ]['available_entities']"
                            :key="field.name"
                            :label="field.name"
                            :value="field.value"
                            :disabled="checkEntityIsSelected(field)"
                          >
                            {{ field["name"] }}
                            <span
                              v-if="
                                field &&
                                field.value &&
                                field.value.split('#')[1] == 'new'
                              "
                              style="color: #f754a2; font-weight: 800"
                              class="ml-2"
                              >NEW</span
                            >
                          </el-option>
                        </el-select>
                        <el-select
                          v-else-if="
                            scope.row.inputType == 'CURRENCY' &&
                            scope.row.template_id.includes('newTemplate') ===
                              true
                          "
                          v-model="scope.row.currency_type"
                          placeholder="Select Currency Type"
                          class="ml-05"
                        >
                          <el-option
                            v-for="field in currencyTypes"
                            :key="field"
                            :label="field"
                            :value="field"
                          >
                          </el-option>
                        </el-select>
                        <div
                          v-else-if="
                            scope.row.inputType == 'AUTO_INCREMENT_NUMBER' &&
                            scope.row.template_id.includes('newTemplate') ===
                              true
                          "
                        >
                          <el-input
                            v-model="scope.row.auto_increment_prefix"
                            class="ml-05"
                            placeholder="Prefix"
                          >
                          </el-input>
                          <el-input
                            v-model="scope.row.auto_increment_starting_value"
                            type="number"
                            class="ml-05"
                            placeholder="Starting Number"
                            @input="handleAutoIncrementChange(scope.row)"
                          >
                          </el-input>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Is primary field">
                    <template slot-scope="scope">
                      <el-checkbox
                        v-model="scope.row.isPrimaryField"
                        @change="isItPrimaryField(scope.row)"
                      >
                      </el-checkbox>
                    </template>
                  </el-table-column>
                </el-table>
              </el-scrollbar>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </section>
      <section v-if="this.active === 2">
        <el-tabs v-model="activeNameForApplicationImport">
          <el-tab-pane
            v-for="tab in Object.keys(jsonDataOfExcel)"
            :label="tab"
            :key="tab"
            :name="tab"
          >
            <SuccessAndErrorsTable
              :templatesData="previewData[tab].templatesData"
              :currentEntity="previewData[tab].entity"
              :foundArray="previewData[tab].fields"
              :successedExcelImportData="previewData[tab].successedData"
            >
            </SuccessAndErrorsTable>
          </el-tab-pane>
        </el-tabs>
      </section>
      <hr />
      <footer>
        <div
          class="footer"
          style="text-align: right"
          v-if="this.active === 0 && creationType == 'Upload File'"
        >
          <el-button @click="goBack">Cancel</el-button>
          <el-button
            type="danger"
            @click="gotoMappingScreen"
            :disabled="buttonOneDisabled"
            >Next</el-button
          >
        </div>
        <div
          class="footer"
          style="text-align: right"
          v-if="
            this.active === 0 && creationType == 'Ask AI' && responseCaptured
          "
        >
          <el-input
            :disabled="checkNextDisabled"
            placeholder="Request for changes"
            v-model="changes_prompt"
            class="input-with-select"
          >
            <el-button
              slot="append"
              :disabled="!changes_prompt || cardLoading"
              @click="generateResponse(true)"
              icon="el-icon-position"
            ></el-button>
          </el-input>
          <span class="ml-1 mr-1">or</span>
          <el-button
            class="btn btn-sm btn-outline-primary"
            type="button"
            style="margin-right: 350px"
            @click="generateResponse()"
            :disabled="checkNextDisabled"
            >Regenerate <i class="el-icon-refresh"></i
          ></el-button>
          <el-button
            type="danger"
            @click="gotoMappingScreen"
            :disabled="checkNextDisabled"
            >Next</el-button
          >
        </div>
        <div
          class="footer"
          style="text-align: right"
          v-else-if="this.active === 1"
        >
          <el-button @click="goBack">Cancel</el-button>
          <el-button
            type="danger"
            @click="getMappingScreenData"
            :disabled="buttonTwoDisabled"
            >Next</el-button
          >
        </div>
        <div
          class="footer"
          style="text-align: right"
          v-else-if="this.active === 2"
        >
          <el-button @click="goBack">Cancel</el-button>
          <el-button
            type="danger"
            @click="importEntitiesData"
            :disabled="buttonThreeDisabled"
            >Create</el-button
          >
        </div>
      </footer>
    </div>
    <dialog-component
      :title="'Add New Group'"
      :visible="showAddGroupDialog"
      :containerWidth="getIsMobile ? '100%' : '30%'"
      @before-close="showAddGroupDialog = false"
      :isShowFooter="false"
    >
      <div v-loading="loadingSaveGroup" class="mt-10 mb-10">
        <span>
          <p>Group Title</p>
          <el-input
            type="text"
            v-model="groupName"
            placeholder="Enter Title"
          ></el-input>
        </span>
        <div class="mt-15">
          <span slot="footer" class="dialog-footer mt-10">
            <el-button @click="showAddGroupDialog = false">Cancel</el-button>
            <el-button
              type="success"
              @click="saveGroup"
              :disabled="!groupName || !groupName.trim()"
              class="px-20"
            >
              <i class="el-icon-check"></i> Save Group
            </el-button>
          </span>
        </div>
      </div>
    </dialog-component>
    <dialog-component
      title="'Create form template'"
      :visible="centerDialogVisible"
      :containerWidth="getIsMobile ? '100%' : '30%'"
      @before-close="centerDialogVisible = false"
      :isShowFooter="false"
    >
      <div class="mt-10 mb-10">
        <span>
          <p for="tempTitle">Template Title</p>
          <el-input
            placeholder="Enter template name"
            v-model="formTemplateName"
            clearable
            id="tempTitle"
          >
          </el-input>
        </span>
        <div class="mt-15">
          <span slot="footer" class="dialog-footer mt-10">
            <el-button @click="centerDialogVisible = false">Cancel</el-button>
            <el-button
              type="primary"
              @click="addTemplate"
              :disabled="formTemplateName.trim().length === 0"
              >Save</el-button
            >
          </span>
        </div>
      </div>
    </dialog-component>
    <el-dialog
      :visible.sync="importDialogueVisible"
      :width="getIsMobile ? '100%' : '35%'"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleCloseDialog"
    >
      <div class="text-center">
        <img
          src="@/assets/img/importDataLoading.gif"
          style="height: 30vh; width: 15vw"
        />
        <p>Please wait</p>
        <h1>Your application creation is in progress...</h1>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="mappingStatusDialogVisible"
      :width="getIsMobile ? '100%' : '40%'"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleCloseDialog"
    >
      <div class="text-center">
        <img
          src="@/assets/img/mappingDone.svg"
          style="height: 30vh; width: 15vw"
        />
        <h1>Your application created successfully...</h1>
        <el-button type="primary" @click="gotoDashboard"> Done </el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="mappingStatusDialogVisibleNewCompany"
      :width="getIsMobile ? '100%' : '40%'"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div class="text-center">
        <img
          src="@/assets/img/mappingDone.svg"
          style="height: 30vh; width: 15vw"
        />
        <h1>Your application created successfully in new workspace...</h1>
        <p>Do you want to switch to the new workspace ?</p>
        <el-button @click="gotoDashboard" style="width: 100px">No</el-button>
        <el-button type="primary" @click="goToNewWorkspace" style="width: 100px"
          >Yes</el-button
        >
      </div>
    </el-dialog>
    <dialog-component
      :title="entityVariableField.excel_field"
      :visible="entityVarDialogVisible"
      :containerWidth="getIsMobile ? '100%' : '30%'"
      @before-close="entityVarDialogVisible = false"
    >
      <div class="entity-variable-container mt-10 mb-10">
        <div>
          <label for="valueOfEntity">Entities</label><br />
          <el-select
            v-model="entityVariableField['relationship_key']"
            placeholder="Select Entity"
            id="valueOfEntity"
            style="width: 90%"
            @change="getSelectedEntityFieldValue"
            clearable
          >
            <el-option
              v-for="(entityField, index) in currentTemplateEntityFields"
              :key="entityField.excel_field + index"
              :label="entityField.excel_field"
              :value="entityField.entity_id + '#' + entityField.excel_field"
            >
              <span
                >{{ entityField.excel_field
                }}<span style="color: #8492a4; font-weight: 700">
                  Template variable</span
                ></span
              >
            </el-option>
          </el-select>
        </div>
        <br />
        <div>
          <label for="varibleValueOfEntity">Entity Variable</label><br />
          <el-select
            v-model="entityVariableField['entityVariableInfo']"
            placeholder="Select Variable"
            id="varibleValueOfEntity"
            style="width: 90%"
            clearable
          >
            <el-option
              v-for="(field, index) in selectedEntityAllFields"
              :key="field.excel_field + index"
              :label="getLabel(field)"
              :value="getValueForEntityVariable(field)"
            >
            </el-option>
          </el-select>
        </div>
        <br />
        <div>
          <label for="fieldAssignable">Is this field ?</label>
          <el-radio-group
            id="fieldAssignable"
            v-model="entityVariableField['field_assignable']"
          >
            <el-radio label="read_only">Read only</el-radio>
            <el-radio label="editable">Editable</el-radio>
            <el-radio label="update_parent">Update parent</el-radio>
          </el-radio-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="entityVarDialogVisible = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="setPropertiesForEntityVariable"
          :disabled="disableEntityVariableButton()"
          >Confirm</el-button
        >
      </span>
    </dialog-component>
    <dialog-component
      :title="'Select Menu Icon'"
      :visible="iconPickerdialogVisible"
      @before-close="iconPickerdialogVisible = false"
      :containerWidth="'80%'"
      :containerHeight="'70%'"
    >
      <icons-list @icon-data="setIconData"></icons-list>
    </dialog-component>
  </section>
</template>
<script>
import * as XLSX from "xlsx";
import { mapGetters } from "vuex";
import PermissionsHelper from "@/mixins/permissionsHelper";
import NavigationHelper from "@/mixins/navigationHelper";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper";
import SuccessAndErrorsTable from "../entity/SuccessAndErrorsTable.vue";
import authHelper from "@/mixins/authHelper";
import moment from "moment";
import axios from "axios";
import { postAPICall } from "@/helpers/httpHelper";
import loadingComponentVue from "../loadingComponent.vue";
// import { clearDB } from "@/repo/appSesttingRepo";
export default {
  data() {
    return {
      active: 0,
      isExcelFileuploaded: false,
      tableLoading: false,
      selectedIcon: "",
      iconPickerdialogVisible: false,
      jsonDataOfExcel: {},
      excelfilecolumnNames: {},
      excelSheetNames: [],
      activeNameForApplicationImport: "",
      groups: [],
      showAddGroupDialog: false,
      groupName: "",
      loadingSaveGroup: false,
      excelFileHeaders: [],
      formTemplateName: "",
      centerDialogVisible: false,
      session_id: "",
      newFormTempCount: 0,
      allFormFIelds: [
        {
          key: "SINGLE_LINE_TEXT",
          name: "Single Line Text",
          img_url: "SingleLine.svg",
        },
        {
          key: "MULTI_LINE_TEXT",
          name: "Multi Line Text",
          img_url: "MultiLine.svg",
        },
        {
          key: "SELECT",
          name: "Select",
          img_url: "Select.svg",
        },
        {
          key: "NUMBER",
          name: "Number",
          img_url: "Number.svg",
        },
        {
          key: "MULTI_SELECT",
          name: "Multiple Select",
          img_url: "MultiSelect.svg",
        },
        {
          key: "DATE",
          name: "Date",
          img_url: "Date.svg",
        },
        {
          key: "TIME",
          name: "Time",
          img_url: "Date.svg",
        },
        {
          key: "DATE_TIME",
          name: "Date Time",
          img_url: "Date.svg",
        },
        {
          key: "YES_OR_NO",
          name: "Yes or No",
          img_url: "YesNo.svg",
        },

        {
          key: "CHECKBOX",
          name: "Checkbox",
          img_url: "CheckBox.svg",
        },
        {
          key: "RADIO",
          name: "Radio",
          img_url: "CheckBox.svg",
        },
        {
          key: "PHONE_COUNTRY_CODE",
          name: "Phone",
          img_url: "Number.svg",
        },
        {
          key: "CURRENCY",
          name: "Currency",
          img_url: "Number.svg",
        },
        {
          key: "IMAGE",
          name: "Image",
          img_url: "File.svg",
        },
        // {
        //     key: "AUTO_INCREMENT_NUMBER",
        //     name: "Auto Increment Number",
        //     img_url: "SingleLine.svg",
        // },
        {
          key: "ENTITY",
          name: "Entity",
          img_url: "glodal_variable.svg",
        },
        {
          key: "ENTITY_VARIABLE",
          name: "Entity Variable",
          img_url: "glodal_variable.svg",
        },
        {
          key: "AUTO_INCREMENT_NUMBER",
          name: "Auto Increment Number",
          img_url: "MultiSelect.svg",
        },
        {
          key: "FILE",
          name: "File",
          img_url: "File.svg",
        },
      ],
      currencyTypes: ["USD", "CAD", "AUD", "INR", "EUR"],
      rowIndex: -1,
      companyEntities: [],
      companyTemplates: [],
      standardTemp: {},
      createdFormTemplate: [],
      previewData: {},
      groupLoading: false,
      importDialogueVisible: false,
      mappingStatusDialogVisible: false,
      mappingStatusDialogVisibleNewCompany: false,
      visitedEntities: {},
      entityVarDialogVisible: false,
      entityVariableField: {},
      selectedEntityAllFields: [],
      currentTemplateEntityFields: [],
      buttonOneDisabled: false,
      buttonTwoDisabled: false,
      buttonThreeDisabled: false,
      creationType: "Upload File",
      application_prompt: "",
      changes_prompt: "",
      cardLoading: false,
      loadingText: "Generating your application... please wait...",
      responseCaptured: false,
      selectValue: "",
      excelFileHeadersApplication: {},
      tablesData: [],
      gotErrorResponse: false,
      errorResponseMessage : "",
      allIconNames: [],
      showGroups: true,
      showEntityTypes: true,
    };
  },
  mixins: [
    PermissionsHelper,
    NavigationHelper,
    TemplateBuilderHelper,
    authHelper,
  ],
  methods: {
    getLabel(field) {
      let label = field.template_id.includes("newTemplate")
        ? field.template_id.split("#")[1]
        : "Contact Details";
      return `${label} -> ${field.excel_field}`;
    },
    getValueForEntityVariable(field) {
      let key = field.excel_field
        .trim()
        .toLowerCase()
        .replace(/[ /&]/g, "_")
        .replace(/[^\w-]+/g, "")
        .replace(/(-)\1+/gi, (str, match) => {
          return match[0];
        });
      return `${field.template_id}#${key}#${field.inputType}`;
    },
    async uploadExcelfile(file) {
      this.excelFile = file;
      let fileNameArray = this.excelFile.name.split(".");
      fileNameArray.splice(fileNameArray.length - 1, 1);
      this.excelFile.name = fileNameArray.join(".");
      this.isExcelFileuploaded = true;
      this.handleFileUpload(this.excelFile);
    },
    setIconData(name) {
      this.excelFileHeadersApplication[this.activeNameForApplicationImport][
        "entity_symbol"
      ] = name;
      this.iconPickerdialogVisible = false;
    },
    removeExcelFile() {
      this.jsonDataOfExcel = {};
      this.isExcelFileuploaded = false;
      this.excelFile = null;
    },
    async handleFileUpload(fileData) {
      const file = fileData.raw;
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = Buffer.from(e.target.result);
        const workbook = XLSX.read(data, { type: "array", cellDates: true });
        this.excelSheetNames = workbook.SheetNames;
        for (const sheetName of workbook.SheetNames) {
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          const convertedJsonData = await this.convertDataToJSON(jsonData);
          let presentSheetName = sheetName;
          let obj = {};
          obj[presentSheetName] = convertedJsonData;
          let columnNamesObj = {};
          columnNamesObj[presentSheetName] = jsonData[0];
          this.excelfilecolumnNames = {
            ...this.excelfilecolumnNames,
            ...columnNamesObj,
          };
          this.jsonDataOfExcel = { ...this.jsonDataOfExcel, ...obj };
        }
        this.activeNameForApplicationImport = this.excelSheetNames[0];
      };
      reader.onerror = (e) => {
        console.error("File reading error:", e);
      };
      reader.readAsArrayBuffer(file);
    },
    async convertDataToJSON(data) {
      const headers = data[0];
      //this.excelfilecolumnNames = [...data[0]];
      const jsonData = [];
      for (let i = 1; i < data.length; i++) {
        const row = data[i];
        const item = {};
        let allKeys = [];
        for (let j = 0; j < headers.length; j++) {
          if (headers[j] && headers[j].includes(".")) {
            headers[j] = headers[j].split(".")[1];
          }
          if (headers[j] && headers[j] !== undefined) {
            const value = row[j] !== null ? row[j] : "";
            let itemKeys = Object.keys(item);
            allKeys.push(headers[j]);
            if (
              itemKeys &&
              itemKeys.length > 0 &&
              itemKeys.includes(headers[j])
            ) {
              let count =
                allKeys.filter((element) => element === headers[j]).length - 1;
              headers[j] = headers[j] + "_" + count;
              item[headers[j]] = value;
            } else {
              item[headers[j]] = value;
            }
          }
        }
        jsonData.push(item);
      }
      return jsonData;
    },
    async generateResponse(isChanges = false) {
      try {
        let prompt = isChanges ? this.changes_prompt : this.application_prompt;
        this.loadingText = `${
          isChanges ? "Updating" : "Generating"
        } your application.It will take upto 1-2 minutes.please wait...`;
        this.cardLoading = true;
        this.gotErrorResponse = false;
        this.excelSheetNames = [];
        let params = {
          application_name: prompt,
          session_id: this.session_id,
          flag: !isChanges,
        };
        let response = await axios.post(
          "https://ai.esigns.io/generate_tables/",
          params
        );
        if (response?.data) {
          this.$message.success("Application generated successfully");
          this.tablesData = response.data?.tables;
          let permission = true;
          this.tablesData.map((e) => {
            if (
              !(e.attributes?.length && e.table_name && e.entity_type && e.icon)
            ) {
              permission = false;
            }
          });
          if (!permission) {
            this.gotErrorResponse = true;
            this.errorResponseMessage = "Some entities are not fully generated. Please use minimal tables."
            this.cardLoading = false;
            return this.$message.warning(
              "Some entities are not fully generated. Please use minimal tables."
            );
          }
          this.responseCaptured = true;
          this.changes_prompt = "";
          this.excelSheetNames = this.tablesData.map((table) => {
            return table.table_name;
          });
        }
        this.cardLoading = false;
      } catch (err) {
        this.$message.warning(
          "We encountered an issue with the response text, and additional efforts are required to create the application at this time."
        );
        this.gotErrorResponse = true;
        this.errorResponseMessage = "We encountered an issue with the response text, and additional efforts are required to create the application at this time."
        this.cardLoading = false;
        console.log("generateResponse", err);
      }
    },
    getCurrentRowFields(row) {
      if (row?.attributes?.length) {
        return row.attributes.map((e) => {
          return {
            name: e.name,
            required: e.required,
            type: e.type,
          };
        });
      }
      return [];
    },
    async goBack() {
      this.$router.push({ path: "/apps" });
    },
    async gotoMappingScreen() {
      this.buttonOneDisabled = true;
      let permission = true;
      if (this.creationType == "Upload File" && !this.isExcelFileuploaded) {
        this.$notify.error({
          title: "Error",
          message: "Please upload a file",
        });
        permission = false;
        return;
      } else if (this.creationType == "Ask AI") {
        if (!this.tablesData?.length) {
          permission = false;
          return this.$notify.error({
            title: "Error",
            message: "No tables detected",
          });
        }
      }

      if (this.excelSheetNames && this.excelSheetNames.length) {
        let emptySheetNameExists = this.excelSheetNames.find(
          (e) => e.trim() == ""
        );
        if (emptySheetNameExists !== undefined) {
          this.$notify.error({
            title: "Error",
            message: "Please make sure that none of the sheet names are empty.",
          });
          permission = false;
          return;
        }
      }
      if (!this.$route.query.workspace) {
        let companyEntityNames = this.companyEntities.map((entity) => {
          return entity.name;
        });
        let index = 0;
        do {
          const name = this.excelSheetNames[index];
          if (companyEntityNames.includes(name)) {
            this.$notify.error({
              title: "Error",
              message: `Entity is already existed with name ${name}`,
            });
            permission = false;
            break;
          }
          index++;
        } while (index < this.excelSheetNames.length);
      }
      if (permission) {
        this.tableLoading = true;
        [this.companyTemplates, this.allIconNames] = await Promise.all([
          this.fetchAllTemplates(),
          this.fetchIconNames(),
        ]);
        if (this.creationType == "Upload File") {
          let fileName = this.excelFile.name;
          var formData = new FormData();
          formData.append(
            "file",
            this.excelFile.raw !== undefined
              ? this.excelFile.raw
              : this.excelFile
          );
          let params = {
            data: formData,
            fileName: fileName ? fileName : "",
          };
          await this.$store.dispatch("entities/storeUploadedFile", params);
          if (this.getstoreUploadedFileStatus) {
            let params = {
              fileName: this.getstoreUploadedFileStatus,
              excelHeaders: this.excelfilecolumnNames,
            };
            let response = await postAPICall(
              "POST",
              "/get-excel-headers-app",
              params
            );
            this.excelFileHeadersApplication = response.data;
          }
        } else {
          this.updateFileHeaders();
        }
        this.active = this.active + 1;
        if (this.$route.query.workspace == undefined) {
          await this.$store.dispatch("entityGroups/fetEntityGroupsData", {
            get_all: true,
          });
          if (this.getAllEntityGroupsData && this.getAllEntityGroupsData.data) {
            this.groups = this.getAllEntityGroupsData.data;
          }
          this.companyEntities = this.companyEntities.filter(
            (e) => e.primaryFields?.length
          );
          Object.keys(this.excelFileHeadersApplication).forEach((e) => {
            this.companyEntities.forEach((entity) => {
              this.excelFileHeadersApplication[e]["available_entities"].push({
                name: entity.name,
                value: `${entity._id}#old`,
                primaryFields: entity.primaryFields[0],
              });
            });
          });
        } else {
          this.groups.push({
            _id: `new#${this.$route.query.workspace}`,
            name: this.$route.query.workspace,
          });
        }
        this.groupLoading = true;
        if (this.groups && this.groups.length) {
          Object.keys(this.excelFileHeadersApplication).forEach((e) => {
            this.excelFileHeadersApplication[e]["groups"].push(
              this.groups[0]._id
            );
          });
        }
        this.groupLoading = false;
        Object.keys(this.excelFileHeadersApplication).forEach((entity) => {
          this.visitedEntities[entity] = false;
        });
        for (let i = 0; i < this.excelSheetNames.length; i++) {
          this.activeNameForApplicationImport = this.excelSheetNames[i];
          this.entityFieldMapping();
        }
        this.activeNameForApplicationImport = this.excelSheetNames[0];
        this.tableLoading = false;
      } else {
        return;
      }
      this.buttonOneDisabled = false;
    },
    async getMappingScreenData() {
      if (this.creationType == "Ask AI") {
        this.activeNameForApplicationImport = this.excelSheetNames[0];
      }
      this.buttonTwoDisabled = true;
      let eachEntityIterator = 0;
      let errorMessagesCount = 0;
      do {
        const eachEntity = Object.keys(this.excelFileHeadersApplication)[
          eachEntityIterator
        ];
        let primaryFieldsCount = 0;
        let emptyFieldCount = 0;
        let entityVariableNotConfiguredCount = 0;

        if (
          this.excelFileHeadersApplication[eachEntity] &&
          this.excelFileHeadersApplication[eachEntity]["columnHeaders"]
        ) {
          this.excelFileHeadersApplication[eachEntity]["columnHeaders"].forEach(
            (field) => {
              if (field && field.isPrimaryField) {
                primaryFieldsCount++;
              }
              if (field.template_id.toString() === "") {
                emptyFieldCount++;
              }
              if (
                field.inputType == "ENTITY_VARIABLE" &&
                (field.entityVariableInfo == "" || field.relationship_key == "")
              ) {
                entityVariableNotConfiguredCount++;
              }
            }
          );
        }
        if (
          this.excelFileHeadersApplication[eachEntity]["entity_type"] === ""
        ) {
          this.$notify.error({
            title: "Error",
            message: `Entity type is required for ${eachEntity}`,
          });
          errorMessagesCount++;
          break;
        } else if (
          this.excelFileHeadersApplication[eachEntity]["groups"] &&
          this.excelFileHeadersApplication[eachEntity]["groups"].length === 0
        ) {
          this.$notify.error({
            title: "Error",
            message: `Atleast one group is required for ${eachEntity}`,
          });
          errorMessagesCount++;
          break;
        } else if (
          this.excelFileHeadersApplication[eachEntity] &&
          this.excelFileHeadersApplication[eachEntity]["columnHeaders"] &&
          emptyFieldCount ===
            this.excelFileHeadersApplication[eachEntity]["columnHeaders"].length
        ) {
          this.$notify.error({
            title: "Error",
            message: `Atleast select one field for ${eachEntity}`,
          });
          errorMessagesCount++;
          break;
        } else if (
          this.excelFileHeadersApplication[eachEntity]["entity_type"] ==
          "INDIVIDUAL"
        ) {
          let allColumnNames = [];
          this.excelFileHeadersApplication[eachEntity]["columnHeaders"].forEach(
            (column) => {
              if (
                column.template_id.toString() ==
                this.standardTemp._id.toString()
              ) {
                allColumnNames.push(column.template_filed_id);
              }
            }
          );
          if (
            !allColumnNames.includes("first_name") &&
            !allColumnNames.includes("last_name") &&
            !allColumnNames.includes("name")
          ) {
            this.$notify.error({
              title: "Error",
              message: "First name and Last name or Name is required",
            });
            errorMessagesCount++;
            break;
          } else if (!allColumnNames.includes("email")) {
            this.$notify.error({
              title: "Error",
              message: "Email is required",
            });
            errorMessagesCount++;
            break;
          }
        } else if (primaryFieldsCount === 0) {
          this.$notify.error({
            title: "Error",
            message: `Atleast one primary field is required for ${eachEntity}`,
          });
          errorMessagesCount++;
          break;
        } else if (entityVariableNotConfiguredCount !== 0) {
          this.$notify.error({
            title: "Error",
            message: `Please configure all the Entity Variable fields for ${eachEntity}`,
          });
          errorMessagesCount++;
          break;
        }
        eachEntityIterator++;
      } while (
        eachEntityIterator <
        Object.keys(this.excelFileHeadersApplication).length
      );
      if (errorMessagesCount > 0) {
        return;
      } else {
        this.tableLoading = true;
        this.activeNameForApplicationImport = this.excelSheetNames[0];
        await this.makePreviewData();
        this.active++;
        this.tableLoading = false;
        this.$notify.success({
          title: "Success",
          message: "Succesfully configured Entities and Templates",
        });
      }
      this.buttonTwoDisabled = false;
    },
    async makePreviewData() {
      Object.keys(this.excelFileHeadersApplication).forEach((entity) => {
        if (!this.jsonDataOfExcel[entity]) {
          return;
        }
        let templates = [];
        this.excelFileHeadersApplication[entity]["formTemplates"].forEach(
          (e) => {
            if (!e._id.includes("newTemplate")) {
              templates.push({
                template_id: {
                  type: "STANDARD",
                },
                _id: e._id,
              });
            }
          }
        );
        let tempData = [];
        this.jsonDataOfExcel[entity].forEach((sheetData) => {
          if (
            this.excelFileHeadersApplication[entity]["entity_type"] ==
            "INDIVIDUAL"
          ) {
            Object.keys(sheetData).forEach((e) => {
              let field = this.excelFileHeadersApplication[entity][
                "columnHeaders"
              ].find((f) => f.excel_field.toString() == e.toString());
              if (!field.template_id.toString().includes("#")) {
                switch (field.template_filed_id) {
                  case "name":
                    sheetData["Name"] = sheetData[e.toString()];
                    break;
                  case "first_name":
                    sheetData["First Name"] = sheetData[e.toString()];
                    break;
                  case "last_name":
                    sheetData["Last Name"] = sheetData[e.toString()];
                    break;
                  case "email":
                    sheetData["Email"] = sheetData[e.toString()];
                    break;
                }
              }
            });
          }
        });
        let primaryFields = [];
        this.excelFileHeadersApplication[entity]["columnHeaders"].forEach(
          (e) => {
            if (e.template_id.toString().includes("#")) {
              e.template_filed_id = e.excel_field
                .trim()
                .toLowerCase()
                .replace(/[ /&]/g, "_")
                .replace(/[^\w-]+/g, "")
                .replace(/(-)\1+/gi, (str, match) => {
                  return match[0];
                });
              e.key = e.template_filed_id;
            } else {
              switch (e.template_filed_id) {
                case "name":
                  e.excel_field = "Name";
                  break;
                case "first_name":
                  e.excel_field = "First Name";
                  break;
                case "last_name":
                  e.excel_field = "Last Name";
                  break;
                case "email":
                  e.excel_field = "Email";
                  break;
              }
              e.key = e.template_filed_id;
            }
            if (e.isPrimaryField) {
              if (e && e.isPrimaryField) {
                primaryFields.push(e);
              }
            }
            if (e.inputType == "SELECT") {
              let selectedOptions = this.jsonDataOfExcel[entity].map((f) => {
                if (f[e.excel_field] !== undefined) {
                  return f[e.excel_field].toString();
                }
              });
              let uniqueOptions = selectedOptions.filter(
                (value, index, self) => {
                  return (
                    value !== undefined &&
                    value.toString() != "" &&
                    self.indexOf(value) === index
                  );
                }
              );
              e.options = [...e.options, ...uniqueOptions];
            } else if (e.inputType == "MULTI_SELECT") {
              this.jsonDataOfExcel[entity].forEach((f) => {
                if (f[e.excel_field] !== undefined) {
                  if (typeof f[e.excel_field] !== "string") {
                    f[e.excel_field] = f[e.excel_field].toString();
                  }
                  if (f[e.excel_field].includes(",")) {
                    let arr = f[e.excel_field].split(",");
                    arr.forEach((op) => {
                      op = op.trim();
                      if (op && !e.options.includes(op)) {
                        e.options.push(op);
                      }
                    });
                  } else {
                    if (
                      f[e.excel_field] &&
                      !e.options.includes(f[e.excel_field])
                    ) {
                      f[e.excel_field] = f[e.excel_field].trim();
                      e.options.push(f[e.excel_field]);
                    }
                    this.jsonDataOfExcel[entity][
                      this.jsonDataOfExcel[entity].indexOf(f)
                    ][e.excel_field] = [f[e.excel_field]];
                  }
                }
              });
            }
          }
        );
        let dummyEntity = {
          entity_type: this.excelFileHeadersApplication[entity]["entity_type"],
          templates: templates,
          primaryFields: primaryFields,
        };
        this.jsonDataOfExcel[entity].forEach((sheetData) => {
          let rowObj = {};
          Object.keys(sheetData).map((e) => {
            this.excelFileHeadersApplication[entity]["columnHeaders"].map(
              (f) => {
                if (f.excel_field.toString() == e.toString()) {
                  let obj = {};
                  if (
                    f.inputType == "MULTI_SELECT" &&
                    sheetData[e] !== "" &&
                    sheetData[e] !== undefined
                  ) {
                    sheetData[e] = sheetData[e].toString();
                    if (sheetData[e].includes(",")) {
                      sheetData[e] = sheetData[e].split(",");
                    } else {
                      sheetData[e] = [sheetData[e]];
                    }
                  } else if (f.inputType == "DATE") {
                    sheetData[e] = moment(sheetData[e]).add(10, "seconds");
                  }
                  // else if (f.inputType == "CURRENCY") {
                  //     if (typeof sheetData[e] !== "number") {
                  //         sheetData[e] = "";
                  //     }
                  // }
                  obj[f.template_filed_id] = sheetData[e];
                  rowObj[f.template_id] = {
                    ...rowObj[f.template_id],
                    ...obj,
                  };
                }
              }
            );
          });
          tempData.push(rowObj);
        });
        this.previewData[entity] = {
          entity: dummyEntity,
          templatesData: tempData,
          fields: this.excelFileHeadersApplication[entity]["columnHeaders"],
          successedData: [],
        };
      });
    },
    updateFileHeaders() {
      this.excelFileHeadersApplication = {};
      this.jsonDataOfExcel = {};
      this.tablesData.map((table, i) => {
        this.excelFileHeadersApplication[table.table_name] =
          this.getTableData(i);
        // this.jsonDataOfExcel[table.table_name] = [];
      });
      this.activeNameForApplicationImport = this.tablesData[0].table_name;
    },
    getTableData(index) {
      let data = this.tablesData[index];
      let preparedData = {
        columnHeaders: [],
        entity_type: data.type ? data.type : "GENERAL",
        groups: [],
        entity_symbol: this.getIcon(data.icon),
        available_entities: [],
        formTemplates: [
          {
            _id: `newTemplate#${data.table_name}#${data.table_name}`,
            name: data.table_name,
          },
        ],
      };
      let sampleData = [];
      data.attributes.map((field, i) => {
        preparedData.columnHeaders.push({
          excel_field: field.name,
          template_id: `newTemplate#${data.table_name}#${data.table_name}`,
          template_filed_id: "",
          inputType: field.type ? field.type : "SINGLE_LINE_TEXT",
          entity_id: "",
          isPrimaryField: i == 0 ? true : false,
          // fieldRepeatedCount: count,
          currency_type: field.currency_type ? field.currency_type : "USD",
          options: field.options ? field.options : [],
          label: field.name,
          key: "",
          entityVariableInfo: "",
          relationship_key: "",
          field_assignable: "read_only",
          auto_increment_prefix: "",
          auto_increment_starting_value: "001",
          is_required: field.required ? field.required : false,
        });
        if (field.sample_data?.length) {
          if (!sampleData.length) {
            field.sample_data.map((data) => {
              sampleData.push({
                [field.name]: data,
              });
            });
          } else {
            field.sample_data.map((data, i) => {
              sampleData[i][field.name] = data;
            });
          }
          // sampleData[field.name] = field.sample_data;
        }
      });
      for (let j = 0; j < index; j++) {
        preparedData.available_entities.push({
          name: this.tablesData[j].table_name,
          value: `${this.tablesData[j].table_name}#new`,
        });
      }
      this.jsonDataOfExcel[data.table_name] = sampleData.length
        ? sampleData
        : [];
      return preparedData;
    },
    getIcon(iconName) {
      return this.allIconNames.includes(iconName)
        ? iconName
        : this.allIconNames[
            Math.floor(Math.random() * this.allIconNames.length)
          ];
    },
    async importEntitiesData() {
      this.buttonThreeDisabled = true;
      this.importDialogueVisible = true;
      let params = {
        // filepath: this.getstoreUploadedFileStatus,
        entitiesAndTemplates: this.excelFileHeadersApplication,
        entitiesAndTemplatesData: this.previewData,
        newWorkspace: false,
      };
      if (this.$route.query.workspace) {
        params["workspaceName"] = this.$route.query.workspace;
        params["newWorkspace"] = true;
        params["groups"] = this.groups;
      }
      await this.$store.dispatch("apps/importApplicationDataForExcel", {
        data: params,
      });
      this.importDialogueVisible = false;
      if (
        this.getAppImportData &&
        this.getAppImportData.data &&
        this.getAppImportData.data.companyId
      ) {
        // this.mappingStatusDialogVisibleNewCompany = true;
        this.goToNewWorkspace();
      } else {
        if (this.getAppImportData == false) {
          this.$notify.error({
            title: "Error",
            message: "Error in creating the application !",
          });
        } else {
          this.mappingStatusDialogVisible = true;
        }
      }
      this.buttonThreeDisabled = false;
    },
    async addGroupPopUP() {
      this.showAddGroupDialog = true;
      await this.$store.dispatch("entityGroups/resetErrors");
    },
    updateGroupInAllEntities() {
      (Object.keys(this.excelFileHeadersApplication) || []).map((e) => {
        this.excelFileHeadersApplication[e]["groups"] =
          this.excelFileHeadersApplication[this.activeNameForApplicationImport][
            "groups"
          ];
      });
      this.$message.success("groups updated in all entities");
    },
    async saveGroup() {
      this.loadingSaveGroup = true;
      if (this.$route.query.workspace == undefined) {
        await this.$store.dispatch("entityGroups/addEntityGroup", {
          name: this.groupName,
          description: "",
          entities: [],
        });
        if (
          this.getSingleEntityGroupData &&
          this.getSingleEntityGroupData.success
        ) {
          this.loadingSaveGroup = false;
          this.groupName = "";
          this.showAddGroupDialog = false;
          this.groups.push(this.getSingleEntityGroupData.data);
          this.excelFileHeadersApplication[this.activeNameForApplicationImport][
            "groups"
          ].push(this.getSingleEntityGroupData.data._id);
        } else if (this.getError && !this.getError.success) {
          this.loadingSaveGroup = false;
          let msg =
            this.getError && this.getError.message
              ? this.getError.message
              : "Something went wrong";
          this.$message.warning(msg);
        }
      } else {
        this.groups.push({
          _id: `new#${this.groupName}`,
          name: this.groupName,
        });
        Object.keys(this.excelFileHeadersApplication).forEach((tab) => {
          this.excelFileHeadersApplication[tab]["groups"].push(
            `new#${this.groupName}`
          );
        });
        this.groupName = "";
        this.loadingSaveGroup = false;
        this.showAddGroupDialog = false;
      }
    },
    entityFieldMapping() {
      let entityFieldOptions =
        this.excelFileHeadersApplication[this.activeNameForApplicationImport][
          "available_entities"
        ];
      let entityPrimaryField = {};
      entityFieldOptions.map((e) => {
        if (e.value.split("#")[1] == "old") {
          entityPrimaryField[e.primaryFields.label] = e.value;
        } else {
          let isPrimary = this.excelFileHeadersApplication[e.name][
            "columnHeaders"
          ].find((e) => e.isPrimaryField == true);
          entityPrimaryField[isPrimary.excel_field] = e.value;
        }
      });
      this.excelFileHeadersApplication[this.activeNameForApplicationImport][
        "columnHeaders"
      ].forEach((field) => {
        if (Object.keys(entityPrimaryField).includes(field.excel_field)) {
          field.inputType = "ENTITY";
          field.entity_id = entityPrimaryField[field.excel_field];
        }
      });
      let allFieldNames = this.excelFileHeadersApplication[
        this.activeNameForApplicationImport
      ]["columnHeaders"].map((field) => {
        return field.excel_field.toLowerCase().replace(/[-_]/g, "");
      });
      if (!this.visitedEntities[this.activeNameForApplicationImport]) {
        let firstNameExists = false,
          lastNameExists = false,
          nameExists = false,
          emailExists = false;
        allFieldNames.forEach((columnName) => {
          if (
            ["first name", "f name", "firstname", "fname"].includes(columnName)
          ) {
            firstNameExists = true;
          } else if (
            [
              "last name",
              "lastname",
              "lname",
              "l name",
              "sname",
              "s name",
              "surname",
              "sur name",
            ].includes(columnName)
          ) {
            lastNameExists = true;
          } else if (columnName.includes("name")) {
            nameExists = true;
          } else if (columnName.includes("mail")) {
            emailExists = true;
          }
        });
        if (
          ((firstNameExists && lastNameExists) || nameExists) &&
          emailExists
        ) {
          this.excelFileHeadersApplication[this.activeNameForApplicationImport][
            "entity_type"
          ] = "INDIVIDUAL";
          this.addStandardTemp("INDIVIDUAL");
        }
      }
      this.visitedEntities[this.activeNameForApplicationImport] = true;
    },
    handleAutoIncrementChange(data) {
      if (["", null, undefined].includes(data.auto_increment_starting_value)) {
        data.auto_increment_starting_value = 1;
      }
    },
    validateSelectedRow(row) {
      if (row.inputType === "ENTITY") {
        row.entity_id = "";
        row.inputType = "";
      }
      row.template_filed_id = "";
    },
    validateInputFields(row) {
      if (row.inputType == "ENTITY_VARIABLE") {
        this.entityVariableField = null;
        let entityFields = this.excelFileHeadersApplication[
          this.activeNameForApplicationImport
        ]["columnHeaders"].filter((e) => {
          if (
            e &&
            e.inputType == "ENTITY" &&
            e.template_id.includes("newTemplate") &&
            row.template_id == e.template_id
          ) {
            return true;
          } else {
            return false;
          }
        });
        this.currentTemplateEntityFields = entityFields ? entityFields : [];
        this.entityVariableField = row;
        this.entityVarDialogVisible = true;
      }
    },
    getSelectedEntityFieldValue() {
      this.selectedEntityAllFields = [];
      if (this.entityVariableField.relationship_key !== "") {
        let selectedEntity = Object.keys(this.excelFileHeadersApplication).find(
          (e) => {
            if (e == this.entityVariableField.relationship_key.split("#")[0]) {
              return this.excelFileHeadersApplication[e]["columnHeaders"];
            }
          }
        );
        this.selectedEntityAllFields =
          this.excelFileHeadersApplication[selectedEntity]["columnHeaders"];
      }
    },
    setPropertiesForEntityVariable() {
      this.excelFileHeadersApplication[this.activeNameForApplicationImport][
        "columnHeaders"
      ].map((header) => {
        if (
          header &&
          header.inputType == "ENTITY_VARIABLE" &&
          header.excel_field == this.entityVariableField.excel_field
        ) {
          header.entityVariableInfo =
            this.entityVariableField["entityVariableInfo"];
          header.relationship_key =
            this.entityVariableField["relationship_key"];
        }
      });
      this.entityVarDialogVisible = false;
    },
    async addStandardTemp(type) {
      // if (this.getAllCompanyTemplatesData && this.getAllCompanyTemplatesData.data) {
      //     this.standardTemp = this.getAllCompanyTemplatesData.data.find(template =>
      //         template.type == 'STANDARD' && template.name == 'Contact details'
      //     )
      // }
      this.showEntityTypes = false;
      if (this.companyTemplates && this.companyTemplates.length) {
        this.standardTemp = this.companyTemplates.find(
          (template) =>
            template.type == "STANDARD" && template.name == "Contact details"
        );
      }
      if (type === "INDIVIDUAL") {
        this.excelFileHeadersApplication[this.activeNameForApplicationImport][
          "formTemplates"
        ].push(this.standardTemp);
        let usedStandardTempFields = {
          first_name: false,
          last_name: false,
          name: false,
          email: false,
        };
        this.excelFileHeadersApplication[this.activeNameForApplicationImport][
          "columnHeaders"
        ].forEach((field) => {
          if (
            field &&
            field.excel_field &&
            (field.excel_field.toString().toLowerCase() == "first name" ||
              field.excel_field.toString().toLowerCase() == "f name" ||
              field.excel_field.toString().toLowerCase() == "firstname" ||
              field.excel_field.toString().toLowerCase() == "fname") &&
            usedStandardTempFields["first_name"] == false
          ) {
            field.template_id = this.standardTemp._id;
            field.template_filed_id = "first_name";
            field.inputType = "SINGLE_LINE_TEXT";
            usedStandardTempFields["first_name"] = true;
          } else if (
            field &&
            field.excel_field &&
            (field.excel_field.toString().toLowerCase() == "last name" ||
              field.excel_field.toString().toLowerCase() == " name" ||
              field.excel_field.toString().toLowerCase() == "lastname" ||
              field.excel_field.toString().toLowerCase() == "lname") &&
            usedStandardTempFields["last_name"] == false
          ) {
            field.template_id = this.standardTemp._id;
            field.template_filed_id = "last_name";
            field.inputType = "SINGLE_LINE_TEXT";
            usedStandardTempFields["last_name"] = true;
          } else if (
            field &&
            field.excel_field &&
            (field.excel_field.toString().toLowerCase() == "name" ||
              field.excel_field.toString().toLowerCase() == "full name" ||
              field.excel_field.toString().toLowerCase() == "fullname" ||
              field.excel_field.toString().toLowerCase().includes("name")) &&
            usedStandardTempFields["name"] == false
          ) {
            field.template_id = this.standardTemp._id;
            field.template_filed_id = "name";
            field.inputType = "SINGLE_LINE_TEXT";
            usedStandardTempFields["name"] = true;
          } else if (
            field &&
            field.excel_field &&
            field.excel_field.includes("mail") &&
            usedStandardTempFields["email"] == false
          ) {
            field.template_id = this.standardTemp._id;
            field.template_filed_id = "email";
            field.inputType = "SINGLE_LINE_TEXT";
            usedStandardTempFields["email"] = true;
          }
        });
      } else {
        this.excelFileHeadersApplication[this.activeNameForApplicationImport][
          "formTemplates"
        ].forEach((template) => {
          if (
            template.type == "STANDARD" &&
            template.name == "Contact details"
          ) {
            this.excelFileHeadersApplication[
              this.activeNameForApplicationImport
            ]["formTemplates"].splice(
              this.excelFileHeadersApplication[
                this.activeNameForApplicationImport
              ]["formTemplates"].indexOf(template),
              1
            );
          }
        });
        this.excelFileHeadersApplication[this.activeNameForApplicationImport][
          "columnHeaders"
        ].forEach((field) => {
          if (
            field.template_id.toString() === this.standardTemp._id.toString()
          ) {
            field.template_id = "";
            field.template_filed_id = "";
          }
        });
      }
      this.showEntityTypes = true;
    },
    ShowTempDialog(index) {
      this.rowIndex = index;
      this.formTemplateName = "";
      this.centerDialogVisible = true;
    },
    addTemplate() {
      if (this.formTemplateName) {
        if (this.companyTemplates.length) {
          this.newFormTempCount++;
          let newTemp = {
            _id: `newTemplate#${this.formTemplateName}#${this.activeNameForApplicationImport}`,
            name: this.formTemplateName,
          };
          let isExisted;
          if (this.$route.query.workspace == undefined) {
            isExisted = this.companyTemplates.find((temp) => {
              if (
                temp.name &&
                newTemp.name &&
                temp.name.toLowerCase() === newTemp.name.toLowerCase()
              ) {
                return true;
              }
            });
          } else {
            isExisted = false;
          }
          if (isExisted || this.createdFormTemplate.includes(newTemp.name)) {
            this.newFormTempCount--;
            this.$notify.error({
              title: "Error",
              message: "Template name is already existed",
            });
          } else {
            this.createdFormTemplate.push(newTemp.name);
            this.excelFileHeadersApplication[
              this.activeNameForApplicationImport
            ]["formTemplates"].push(newTemp);
            for (
              let i = this.rowIndex;
              i <
              this.excelFileHeadersApplication[
                this.activeNameForApplicationImport
              ]["columnHeaders"].length;
              i++
            ) {
              this.excelFileHeadersApplication[
                this.activeNameForApplicationImport
              ]["columnHeaders"][i].template_id = newTemp._id;
            }
            this.centerDialogVisible = false;
          }
        } else {
          this.newFormTempCount++;
          let newTemp = {
            _id: `newTemplate#${this.formTemplateName}#${this.activeNameForApplicationImport}`,
            name: this.formTemplateName,
          };
          this.createdFormTemplate.push(newTemp.name);
          this.excelFileHeadersApplication[this.activeNameForApplicationImport][
            "formTemplates"
          ].push(newTemp);
          this.centerDialogVisible = false;
        }
      } else {
        return;
      }
    },
    isItPrimaryField() {},
    getTemplateFields(row) {
      if (
        row &&
        row.template_id &&
        this.companyTemplates &&
        this.companyTemplates.length
      ) {
        let foundedTemp = this.companyTemplates.find((temp) => {
          if (
            temp &&
            temp._id &&
            row.template_id &&
            temp._id == row.template_id
          ) {
            return true;
          } else {
            return false;
          }
        });
        let contentFields = [
          "HEADING",
          "PARAGRAPH",
          "HTML_CONTENT",
          "SINGLE_LINE_CONTENT",
          "VIDEO",
          "ACTION_BUTTON",
          "HORIZONTAL_LINE",
          "DIVISION",
        ];
        if (
          foundedTemp &&
          foundedTemp.sections[0] &&
          foundedTemp.sections[0].fields
        ) {
          let fields = [];
          foundedTemp.sections[0].fields.forEach((field) => {
            if (
              field &&
              field.input_type &&
              contentFields.includes(field.input_type) === false
            ) {
              fields.push(field);
            }
          });

          return fields.map((e) => {
            e.template_id = row.template_id;
            return e;
          });
        } else {
          return [];
        }
      }
      return [];
    },
    checkIsFieldSelected(field) {
      return this.excelFileHeadersApplication[
        this.activeNameForApplicationImport
      ]["columnHeaders"].find(
        (e) =>
          e.template_id == field.template_id && e.template_filed_id == field.key
      )
        ? true
        : false;
    },
    checkEntityIsSelected(field) {
      let exist = this.excelFileHeadersApplication[
        this.activeNameForApplicationImport
      ]["columnHeaders"].find(
        (e) =>
          e.inputType == "ENTITY" &&
          e.entity_id.toString() == field.value.toString()
      );
      return exist ? true : false;
    },
    async getAllCompanyEntities() {
      // await this.$store.dispatch("entities/fetchEntities", { get_all: true });
      // if (this.getAllEntities && this.getAllEntities.data) {
      //     this.companyEntities = this.getAllEntities.data
      // }
      this.companyEntities = await this.fetchAllEntities(false);
    },
    handleCloseDialog() {
      this.$router.push("/entity");
    },
    gotoDashboard() {
      this.mappingStatusDialogVisible = false;
      this.$router.push("/entity");
    },
    async getCompanyUserType(activeRole) {
      try {
        await this.$store.dispatch("auth/getUserTypeById", activeRole);
      } catch (err) {
        console.log("getCompanyUserType", err);
        this.$notify.error({
          title: "Error",
          message: err,
        });
      }
    },
    async goToNewWorkspace() {
      // this.$router.push("/entity");
      this.mappingStatusDialogVisibleNewCompany = false;
      await this.changeWorkspace(this.getAppImportData.data.companyId);
    },
    handleGroupChange() {
      this.showGroups = false;
      this.showGroups = true;
    },
    disableEntityVariableButton() {
      if (
        this.entityVariableField.relationship_key == "" ||
        this.entityVariableField.entityVariableInfo == ""
      ) {
        return true;
      }
      return false;
    },
    handleActiveChange(target) {
      if (target < this.active) {
        switch (target) {
          case 0:
            break;
          case 1:
            break;
        }
        this.active = target;
      } else {
        this.$message.warning("Please click next");
      }
    },
  },
  computed: {
    ...mapGetters("entityGroups", [
      "getAllEntityGroupsData",
      "getSelectedGroupsData",
      "getSingleEntityGroupData",
    ]),
    ...mapGetters("entities", [
      "getstoreUploadedFileStatus",
      "getExcelFileHeadersApplication",
      "getAllEntities",
      "getEntityDataById",
    ]),
    ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    ...mapGetters("apps", [
      "getAllEntitiesPreviewMappingData",
      "getAppImportData",
    ]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getAuthenticationStatus",
      "getActiveWorkspace",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    checkNextDisabled() {
      return this.cardLoading || !this.responseCaptured;
    },
  },
  async mounted() {
    this.session_id = `${this.getActiveWorkspace.company_id}#${this.getAuthenticatedUser._id}`;
    this.application_prompt = this.$route.query?.workspace
      ? this.$route.query.workspace
      : " ";
    await this.getAllCompanyEntities();
  },
  components: {
    SuccessAndErrorsTable,
    loadingComponentVue,
  },
};
</script>
<style lang="scss">
.match_upload_button {
  height: 10vh;
  border: 1.5px solid #f754a2;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 10px 10px !important;
  margin-left: 10px !important;
  height: 35px;
  color: #f754a2;
  background-color: white;
}

.uploaded-file-display {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  width: 36vw;
  height: 8vh;
}
</style>
<style scoped>
.class-ai {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin : auto; */
}
.input-field {
  width: 100%;
}
.input-with-select {
  width: 30%;
  margin-right: 10px;
}
.generate-button {
  margin-top: 15px;
}
.box-card {
  width: 50%;
  border: 0.5px dashed #dcdfe6;
}
.box-card2 {
  border: 0.5px dashed #dcdfe6;
  min-height: 430px;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.step-class .icon-class {
  cursor: pointer;
}
.icon-class {
  cursor: pointer;
}
</style>