<template>
  <section>
    <el-card class="box-card">
      <el-progress
        :stroke-width="15"
        :color="getCompanyDetails.dialog_header_color"
        :percentage="percentage"
        :show-text="false"
      ></el-progress>
      <div>
        <i class="el-icon-loading"></i>
        <p>{{ loadingText }}</p>
      </div>
      <el-divider></el-divider>
      <div class="main-text">
        Experience the future of development with AI-driven solutions,
        delivering custom applications faster than ever.
      </div>
      <ul class="features-list">
        <li v-for="feature in visibleFeatures" :key="feature">
          <span>•</span> {{ feature }}
        </li>
      </ul>
    </el-card>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "loadingComponent",
  props: ["loadingText", "features", "successMessage"],
  data() {
    return {
      percentage: 0,
      timer: null,
      featureTimer: null,
      visibleFeatures: [],
      defaultFeatures: [
        "Enable rapid prototyping and development, allowing businesses to quickly create and deploy applications without the need for extensive coding.",
        "Harness the speed of generative AI, ensuring your custom applications are always ahead of schedule.",
        "Transform ideas into reality quickly with AI capabilities, ensuring swift custom application delivery.",
        "Design any custom software solution without writing a single line of code.",
        "Reduce the need for professional developers with our no-code platform and significantly lower development costs, making it more affordable for small and medium-sized businesses.",
        "Kodefast allows non-developers, such as business analysts and project managers, to build and customize applications, democratizing the development process.",
        "Kodefast enables quick adjustments and iterations, allowing businesses to respond swiftly to changing market conditions and customer needs.",
      ],
      currentFeatures : this.features
    };
  },
  computed: {
    ...mapGetters("company", ["getCompanyDetails"]),
  },
  beforeDestroy() {
    // this.$message.success(this.successMessage);
    clearInterval(this.timer);
    clearInterval(this.featureTimer);
    this.percentage = 100;
    setTimeout(() => {
      this.percentage = 0;
    }, 1000);
  },
  mounted() {
    if (!this.currentFeatures?.length) {
      this.currentFeatures = this.defaultFeatures;
    }
    this.startProgress();
    this.showFeatures();
  },
  methods: {
    startProgress() {
      this.timer = setInterval(() => {
        if (this.percentage < 96) {
          this.percentage += 1;
        } else {
          clearInterval(this.timer);
        }
      }, 800);
    },
    showFeatures() {
      this.visibleFeatures.push(this.currentFeatures[0]);
      let index = 1;
      this.featureTimer = setInterval(() => {
        if (index < this.currentFeatures.length) {
          this.visibleFeatures.push(this.currentFeatures[index]);
          index++;
        } else {
          clearInterval(this.featureTimer);
        }
      }, 4000);
    },
  },
};
</script>

<style scoped>
.box-card {
  padding: 0px 20px 20px 20px;
  border-radius: 5px;
  background-color: #f5f5f5;
}

.main-text {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.features-list {
  list-style: none;
  padding: 10px;
  margin: 0;
  text-align: left;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
}

.features-list li {
  margin-bottom: 5px;
  padding: 2px 0px;
  transition: background-color 0.3s;
}

.features-list li:hover {
  background-color: #e0e0e0; /* Changes background color on hover */
}

.el-divider--horizontal {
  margin: 12px 0px 16px 0px;
}
</style>